<template>
  <!-- 活动管理 -->
  <div class="page-container">
    <div class="page-top-tool">
      <!-- <el-button type="success" icon="el-icon-plus" size="mini">新增网点</el-button> -->
      <div class="right-search">
        <!-- <span>所属地区：</span>
        <el-select v-model="area" clearable filterable placeholder="请选择" size="mini" style="width: 160px;">
          <el-option
            v-for="item in areaList"
            :key="item.id"
            :label="item.name"
            :value="item.name">
          </el-option>
        </el-select>
        <span style="margin-left: 20px;">团队经理：</span>
        <el-select v-model="fcode" placeholder="请选择" filterable clearable size="mini" style="width: 180px;">
          <el-option
            v-for="item in fnameOptions"
            :key="item.empcode"
            :label="`${item.empname} ${item.emparea ? '（'+item.emparea+'）' : ''}`"
            :value="item.empcode">
          </el-option>
        </el-select>
        <span style="margin-left: 10px">规划师：</span>
        <el-select v-model="scode" placeholder="请选择" filterable clearable size="mini" style="width: 160px;">
          <el-option
            v-for="item in snameOptions"
            :key="item.id"
            :label="item.empname"
            :value="item.empcode">
          </el-option>
        </el-select>
        <span style="margin-left: 10px">活动月份：</span>
        <el-date-picker
          v-model="month"
          type="month"
          size="mini"
          :clearable="false"
          placeholder="选择日期">
        </el-date-picker>
        <el-button type="primary" icon="el-icon-search" size="mini" style="margin: 0px 20px;" @click="onSearch">搜索</el-button> -->
        <el-page-header @back="goBack" :content="`导出活动数据`"></el-page-header>
      </div>
    </div>
    <div style="margin-top: 15px;display: flex;justify-content: space-between;">
      <div>
        <span style="margin-left: 10px">选择月份：</span>
          <el-date-picker
            v-model="month"
            type="month"
            size="mini"
            :clearable="false"
            placeholder="选择日期"
            :disabled="loading"
            @change="changeDate">
          </el-date-picker>
      </div>
      <el-button type="success" size="mini" icon="el-icon-download" v-if="!loading" style="margin-left: 15px;" @click="onDownloadExcel">下载</el-button>
    </div>
    <el-table
      v-loading="loading"
      element-loading-text="数据加载中"
      element-loading-spinner="el-icon-loading"
      id="tableId"
      :data="monthTableData"
      style="width: 100%; font-size: 12px; margin: 20px 0px;"
      :header-cell-style="tableHeaderColor"
      height="80%"
    >
    <el-table-column
            fixed
            prop="id"
            label="ID">
          </el-table-column>
          <el-table-column
            fixed
            prop="empname"
            label="规划师">
          </el-table-column>
          <el-table-column
            prop="fname"
            label="所属团队"
            width="150">
          </el-table-column>
          <el-table-column
            prop="area"
            label="所属地区"
            width="130">
          </el-table-column>
          <el-table-column
            prop="activitydate"
            label="活动日期"
            width="200">
          </el-table-column>
          <el-table-column
            prop="storename"
            label="活动网点"
            width="400">
          </el-table-column>
          <el-table-column
            prop="location"
            label="活动定位"
            width="400">
          </el-table-column>
          <el-table-column label="邀约客户数量" prop="yaoyuenum">
          </el-table-column>
          <el-table-column label="到场客户数量" prop="daochangnum">
          </el-table-column>
          <el-table-column label="满期客户数量" prop="manqinum">
          </el-table-column>
          <el-table-column label="意向出单客户数量" prop="yixiangnum">
          </el-table-column>
          <el-table-column label="审核状态">
            <template slot-scope="scope">
              <span v-if="scope.row.state == '1'">待审核</span>
              <span v-else-if="scope.row.state == '2'">已通过</span>
              <span v-else-if="scope.row.state == '3'">未通过</span>
            </template>
          </el-table-column>
          <el-table-column label="活动提交时间" prop="createtime" width="180"></el-table-column>
          <el-table-column label="最后审核时间" prop="examintime" width="180"></el-table-column>
          <el-table-column label="条幅照片" width="600">
            <template slot-scope="scope">
              <a :href="`${baseurl}/images/${scope.row.tiaofu}`" target="_blank">
                {{ `${baseurl}/images/${scope.row.tiaofu}` }}
              </a>
            </template>
          </el-table-column>
          <el-table-column label="海报照片" width="600">
            <template slot-scope="scope">
              <a :href="`${baseurl}/images/${scope.row.haibao}`" target="_blank">
                {{ `${baseurl}/images/${scope.row.haibao}` }}
              </a>
            </template>
          </el-table-column>
          <el-table-column label="物料照片" width="600">
            <template slot-scope="scope">
              <a :href="`${baseurl}/images/${scope.row.wuliao}`" target="_blank">
                {{ `${baseurl}/images/${scope.row.wuliao}` }}
              </a>
            </template>
          </el-table-column>
          <el-table-column label="签到照片" width="600">
            <template slot-scope="scope">
              <a :href="`${baseurl}/images/${scope.row.qiandao}`" target="_blank">
                {{ `${baseurl}/images/${scope.row.qiandao}` }}
              </a>
            </template>
          </el-table-column>
          <el-table-column
            v-for="(item, index) in 6"
            :key="index"
            :label="`现场照片${item}`"
            width="600">
            <template slot-scope="scope">
              <a v-if="scope.row.xianchang.length >= item" :href="`${baseurl}/images/${scope.row.xianchang[index]}`" target="_blank" style="margin: 0px 5px;">
                  {{ `${baseurl}/images/${scope.row.xianchang[index]}` }}
              </a>
            </template>
          </el-table-column>
    </el-table>
  </div>
</template>

<script>
import global from '@/utils/global.js'
import JSZip from 'jszip'
import FileSaver from "file-saver"
import * as XLSX from "xlsx"
export default {
  name: 'ActivityPage',
  data() {
    return {
      loading: false,
      month: '', // 月份
      baseurl: global.baseurl,
      monthDataTitle: '', // 导出数据表头
      monthTableData: [], // 导出数据表格
    };
  },
  mounted() {
    this.month = new Date()
    this.initData()
  },

  methods: {
    goBack() {
      try {
        this.$router.back()
      } catch(e) {
        this.$router.push({
          path: '/activity'
        })
      }
    },
    async initData() {
      this.loading = true
      const empResp = await this.$http({
        method: 'post',
        url: 'employee_findall'
      })
      if (empResp.errno == 0) {
        this.employeeList = empResp.data.data
      } else {
        this.$message.error('员工加载失败')
      }

      const storeResp = await this.$http({
        method: 'post',
        url: 'store_findall'
      })
      if (storeResp.errno == 0) {
        this.allStoreList = storeResp.data.data
      } else {
        this.$message.error('网点加载失败')
      }

      const areaResp = await this.$http({
        method: 'post',
        url: 'area_findall'
      })
      if (areaResp.errno == 0) {
        this.areaList = areaResp.data.data
      } else {
        this.$message.error('地区加载失败')
      }

      this.exportMonthData()
    },
    // 选择日期事件
    changeDate(date) {
      this.month = date
      this.exportMonthData()
    },
    // 查询的数据添加地区字段
    addAreaFiled(list, type) {
      list.forEach(data => {
        for (let i = 0; i < this.allStoreList.length; i++) {
          const item = this.allStoreList[i]
          if (data.empcode == item.fcode || data.empcode == item.scode) {
            data.area = item.area
          }
        }
      })
    },
    tableHeaderColor({row, column, rowIndex, columnIndex}) {
      return 'background-color: #F2F2F2;color:#101010;'
    },
    // 导出数据
    exportMonthData() {
      const year = this.month.getFullYear()
      const month = this.month.getMonth() + 1
      this.monthDataTitle = `${year}年${month}月活动总数据`
      this.monthTableData = []
      this.loading = true
      this.$http({
        method: 'post',
        url: 'activity_findall',
        params: {
          activitydate:  `${year}/${month}`,
          // state: '1'
        }
      })
      .then(res => {
        if (res.errno == 0) {
          this.monthTableData = res.data.data.map(item => {
            if (item.xianchang && typeof item.xianchang == 'string') {
              item.xianchang = item.xianchang.split(',')
            }
            return item
          })
          this.addAreaFiled(this.monthTableData)
        }
        this.loading = false
      })
      .catch(err =>{
        this.loading = false
        this.$message.error('活动查询异常')
        console.error('活动查询异常：',err.message);
      })
    },
    // 下载Excel数据
    onDownloadExcel() {
      //转换成excel时，使用原始的格式
				var xlsxParam = { raw: true };
				let fix = document.querySelector(".el-table__fixed");
				let wb;
        //判断有无fixed定位，如果有的话去掉，后面再加上，不然数据会重复
				if (fix) {
				  wb = XLSX.utils.table_to_book(
				  document.querySelector("#tableId").removeChild(fix),xlsxParam
				  );
				  document.querySelector("#tableId").appendChild(fix);
				} else {
          wb = XLSX.utils.table_to_book(document.querySelector("#tableId"),xlsxParam);
        }
				var wbout = XLSX.write(wb, {
          bookType: "xlsx",
          bookSST: true,
          type: "array",
				});
				try {
				  FileSaver.saveAs(new Blob([wbout], { type: "application/octet-stream" }),`${this.monthDataTitle}.xlsx`); //文件名
				} catch (e) {
				  if (typeof console !== "undefined") console.log(e, wbout);
				}
				return wbout
    }
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  background: #fff;
  height: 100%;
  width: 100%;
  border-radius: 3px;
  box-sizing: border-box;
  padding: 20px 25px;
  overflow-y: scroll;

  .page-top-tool {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
  }
}

/* 修改滚动条样式 */
.page-container::-webkit-scrollbar {
	width: 4px;
}
.page-container::-webkit-scrollbar-thumb {
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
	background: rgba(0, 0, 0, 0.2);
}

::v-deep .el-dialog {
  width: 60%;
  height: 700px;
  top: -20px;
}

.show-images {
  height: 480px;
}
.active-detail {
  position: relative;
  .shenpi-resuilt-img {
    position: absolute;
    width: 120px;
    top: -70px;
    right: 0px;
    opacity: 0.6;
  }

  .active-images-layout {
    display: flex;
    flex-wrap: wrap;
    .active-images-item {
      margin: 20px;
      cursor: pointer;
    }
  }
}

.active-detail-dialog {
  height: 480px;
  overflow-y: scroll;
}
.active-detail-dialog-bottom {
  margin-top: 20px;
  text-align: right;
}

/* 修改滚动条样式 */
.active-detail-dialog::-webkit-scrollbar {
	width: 4px;
}
.active-detail-dialog::-webkit-scrollbar-thumb {
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
	background: rgba(0, 0, 0, 0.2);
}

</style>